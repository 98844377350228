import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import Img from "gatsby-image";
import SEO from '../../components/seo';
import ImageLandscape from '../../components/sub/landscape.js';
import PortraitsTwo from '../../components/sub/portraitx2';
export const frontmatter = {
  title: "Unfinished Business: Imperfection’s Catalyst Role in Creativity & Innovation",
  date: "2017-12-11",
  author: "Roberto Ramos",
  authorTitle: "Senior Vice President, Strategy and Communications of The Doneger Group",
  teaserImage: "../../../static/media/20171211-0.jpg",
  path: "2017-12-11-Unfinished-Business",
  teaserText: "Our social media era and its endless set of distractions have hampered our ability to finish things. "
};
const layoutProps = {
  frontmatter
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>

      <SEO title="Unfinished Business: Imperfection’s Catalyst Role in Creativity & Innovation" description="Our social media era and its endless set of distractions have hampered our ability to finish things. " author='Roberto Ramos' keywords={['Robots']} />
      <ImageLandscape imageSrc="20171211-0.jpg" caption="Dove" />
      <MDXTag name="p" components={components}>{`Our social media era and its endless set of distractions have hampered our ability to finish things. We are living in a messy state of intermittent craving and explorations triggered by ongoing streams of shifting stimuli. But before we go all doomsday on this seemingly unfocused predicament, we shall stop and explore why this might be a good thing. And the reason is that we are no longer held captive to the notion and pressure of delivering something immutable.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Whereas Guttenberg and his printing press in the 1500s ushered a then-new revolution around the spread of ideas, some of them dogmatic; today’s digital revolution unleashes the power of experimenting. This new moment favors extreme reiteration, unfinished formats and open-source collaborations instead of a finite product.  This embracement of imperfection has great repercussions in terms of how we come up with new ideas and how we collaborate. It’s also bound to give us the courage to fail fantastically on the way to awesomeness.`}</MDXTag>
      <MDXTag name="p" components={components}>{`After a timeless obsession with traditional perfection and beauty, we are opening up to the freedom of imperfection. The concept is appealing for a different set of reasons. First of all, because it reflects our natural, changing selves. We see this ushering of “imperfection” in the embracement of body positivity, owning what we previously called flaws as now powerfully unique attributes of our individuality. This is expressed, for instance, in the woke-inspired criticism of beauty narratives of yore, including modern vestiges such as photo-shopped beauty images. Imperfection’s strength in this case lies in its direct challenge to traditional beauty norms.`}</MDXTag>
      <ImageLandscape imageSrc="20171211-1.jpg" caption="Dove" />
      <MDXTag name="p" components={components}>{`Imperfection’s modern appeal also taps into a post-digital desire for things as they exist naturally, pre any form of digital alteration. We see this in the rise of the “ugly” food movement, with its rescue of slightly damaged, but otherwise perfectly fine and delicious produce. We are also seen in the increasingly popular Japanese design form of Wabi-Sabi, centered on the idea of transience and imperfection. In a more nostalgic vein, we are seeing the rise of low-fi, pixelated early ’90s aesthetics. Again, the idea of the past as ideally less complex is something that speaks to our cyber-harried selves.`}</MDXTag>
      <ImageLandscape imageSrc="20171211-2.jpg" caption="Home Decor" />
      <ImageLandscape imageSrc="20171211-3.jpg" caption="Ugly Food Movement" />
      <MDXTag name="p" components={components}>{`Imperfection expressed in the form of disruptive and slightly dystopian aesthetic also strikes a particular chord today, especially with incoming consumers questioning the establishment. At a time when Facebook gives you emoticon options to show your mood; what stronger statement than your sartorial choices putting your values front and center?`}</MDXTag>
      <PortraitsTwo data={[{
        imageSrc: "20171211-g1.jpg",
        caption: "Gucci S/S18"
      }, {
        imageSrc: "20171211-g2.jpg",
        caption: "Gucci S/S18"
      }]} />
      <MDXTag name="p" components={components}>{`We are seeing the rise of “ugly” fashion by both edgier, elevated streetwear brands such as Supreme as well as by luxury marquee brands such as Gucci and its creative director Alessandro Michelle’s penchant for unmatched, granny-chic aesthetics. We also see this anti-norm appetite in the rise of modern-day tribes such as the new feminist witch movement and the surging return of a modern version of rebel punk. In these examples the message is unapologetically urgent and direct.`}</MDXTag>
      <ImageLandscape imageSrc="20171211-4.jpg" capton="Women's Rights March 2017" />
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Imperfection in Business`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`The nature of failure and imperfection is also driving the conversation in business and innovation. Creative chaos and creative disruption are two of the most professed innovation moments, and failure is the new arbiter for an innovative DNA. Tesla’s Elon Must, for instance, states that “If things are not failing, you are not innovating enough.”`}</MDXTag>
      <MDXTag name="p" components={components}>{`An openness to failure relaxes the creative muscles, shedding all sorts of insecurities. This must be done with mechanisms and checkpoints that value the findings from those failures through the right inputs, questions and collaborative processes. This is failure for the sake of playing it to get it right, or at least as right as possible for the time being, knowing that something better is inevitable. Hence the world and economy of updates and product re-launches and seemingly chasing the always slightly elusive perfect.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`What’s to Come?`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Our world is only becoming more complex as we question reality with augmented layering; as the environment serves us lessons of biblical proportions; and as cyber security challenges our notions of identity and safety in profoundly new ways. This uncertainty will carry shifting and as result ongoing imperfect solutions and systems, putting imperfection further at the core of our experience.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Embracing Imperfection for Change`}</MDXTag></MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`Explore the world of uncertainty and instability in your product category. What are those ongoing shifts? Design systems that openly embrace the change. Think of mutable design and open source mechanisms that allow for iterative betterment.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`A work in progress. Modern day consumers and key audiences want to be vested in a brand experience. Do not feel the pressure to show a finished product. Whenever possible, allow them in the behind the scenes, unveil the creative chaos. This is great for true product development, but also for deeper consumer engagement.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Give consumers the unexpected. A curated, creatively messy experience can surprise consumers. Think of unexpected lifestyle-centered adjacencies in-store, for instance, to create a more magical sense of discovery.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Embrace your brand’s imperfections. No company or brand has it all figured out. Look for ways to strategically convey how you’re working on your shortcomings or addressing new challenges. Enlist the help of experts, partners and the consumer at large. Areas to explore include next-gen corporate social responsibility and marketing, arenas where popular sentiment and an outside perspective is critical.`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`But most importantly, embrace your own comfort level with change, with messiness, with pitching ideas that seem unfinished to those that can help shape them and take them to a better place. This new economy is not for the hyper-polished, but for the innovatively imperfect. So go ahead and let those flaws roam.`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    